@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
* {
  font-family: "Noto Sans JP", sans-serif;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

*::-webkit-scrollbar {
  width: 0.3em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(202, 202, 202);
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.MuiTextField-root {
  width: "100%";
}
/* .MuiFormControl-root {
  width: 100%;
} */
